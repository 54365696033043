import React, { useState } from "react";
import styles from "./styles.module.scss";
import Icon from "@components/shared/Icon";
import Button from "@components/shared/Button";
import classnames from "classnames";
import RequestForm from "../requestForm";
import { getText } from "~/src/libs/resources";

const PromoQuestionBanner = ({
  promoClassname,
  isStorePage,
  isBrandPage
}: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };
  return (
    <div
      className={
        isStorePage
          ? styles.StorePromoQ
          : promoClassname === "searchPagePromoQ"
          ? styles.searchPromoQ
          : promoClassname === "promoQ"
          ? styles.promoQ
          : "container mt-10"
      }
    >
      <div className={classnames(styles.itemWrapper, "py-20")}>
        <div>
          <Icon name="find_product" width={54} height={54} />
        </div>
        <h3 className={classnames(styles.title)}>
          {isBrandPage
            ? getText().Brand.CannotFindBrand
            : getText().PLP.CannotFindProduct}
        </h3>
        <Button
          theme="primary"
          className={classnames(styles.btnWrapper, "font-bold-base bg-primary")}
          onClick={showModal}
        >
          {getText()?.General?.RequestIt}
        </Button>
        <RequestForm
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
        />
      </div>
    </div>
  );
};

export default PromoQuestionBanner;
