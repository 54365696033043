import styles from "./styles.module.scss";
import SearchResult from "./SearchResult";
import classnames from "classnames";
import SearchBox from "./SearchBox";
import { useState, useEffect, useContext, useRef } from "react";
import { AppContext } from "~/src/libs/context";
import { useWindowResize } from "@src/utilities/hooks/useWindowResize.js";
import Overlay from "../../../Overlay";

const Search = ({ isSearchPage, getItemId, searchRef, open, setOpen }: any) => {
  const { setTyping, openMenuSearch, setOpenMenuSearch, isStoreSearch, setIsStoreSearch } =
    useContext<any>(AppContext);
  const [openMenu, setOpenMenu] = useState(open || false);
  const [isTrending, setIsTrending] = useState(true);
  const [data, setData] = useState([]);
  const windowWidth = useWindowResize();

  const searchBoxRef = useRef<HTMLDivElement>(null);
  const searchResultRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (typeof open === "boolean" && open) {
      showMenu();
      if (searchRef && searchRef.current) {
        searchRef.current.focus(); 
      }
    } else {
      closeMenu();
    }
  }, [open]);

  const showMenu = () => {
    setOpenMenu(true);
    if (windowWidth <= 993) {
      setOpenMenuSearch(true);
    } else {
      setOpenMenuSearch(false);
    }

    setOpen instanceof Function && setOpen(true);
  };
  const closeMenu = () => {
    setTyping(0);
    setOpenMenu(false);
    setOpenMenuSearch(false);
    setOpen instanceof Function && setOpen(false);
    document.body.style.overflow = "auto";
  };
  const handleClickOutside = (event: MouseEvent) => {
    if (
      searchBoxRef.current &&
      !searchBoxRef.current.contains(event.target as Node) &&
      searchResultRef.current &&
      !searchResultRef.current.contains(event.target as Node)
    ) {
      closeMenu();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(()=>{
     if(isStoreSearch){
      closeMenu()
      setIsStoreSearch(false)
     }
  },[isStoreSearch])

  const isTrendingHandler = (trending: boolean) => {
    setIsTrending(trending);
  };

  return (
    <>
      {!isSearchPage && openMenu && windowWidth < 992 && <Overlay closeMenu={closeMenu} />}
      <div
        className={classnames(
          styles.searchModal,
          getItemId ? styles.customWidth : ""
        )}
      >
        <div ref={searchBoxRef}>
          <SearchBox
            closeMenu={closeMenu}
            showMenu={showMenu}
            openMenu={openMenu}
            setData={setData}
            data={data}
            isSearchPage={isSearchPage}
            isComparePage={getItemId ? true : false}
            searchRef={searchRef}
            isTrendingHandler={isTrendingHandler}
          />
        </div>
        <div ref={searchResultRef} className={styles.desktopSearch}>
          {(openMenu && windowWidth >= 992) ?(
            <SearchResult
              getItemId={getItemId}
              setData={setData}
              searchResults={data}
              openMenu
              closeMenu={closeMenu}
              showMenu={showMenu}
              isTrending={isTrending}
            />
          ): null}
        </div>
        <div className={styles.mobileSearch}>
          {openMenu && openMenuSearch && (
            <SearchResult
              getItemId={getItemId}
              setData={setData}
              searchResults={data}
              openMenu
              closeMenu={closeMenu}
              showMenu={showMenu}
              isTrending={isTrending}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Search;
