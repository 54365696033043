var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"K-7WFek0CLOLFusk4z7BE"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client side.
// The config added here will be used when a user loads a page in their browser.
// Documentation: https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

console.log("Initializing Sentry on client...");

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const ENVIRONMENT = process.env.NEXT_PUBLIC_SENTRY_ENV || 'development';
const isProd = process.env.NODE_ENV === 'production';

// Global event timing control
let lastEventSentAt = 0;
const MIN_TIME_BETWEEN_EVENTS = 30000; // 30 seconds

// Initialize Sentry only if in a production or staging environment
if (ENVIRONMENT !== 'local') {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENVIRONMENT,

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.005,

    // Toggle debug mode based on environment
    debug: !isProd,

    // Drop events that are too frequent or not severe enough
    beforeSend(event, hint) {
      const now = Date.now();
      if (now - lastEventSentAt < MIN_TIME_BETWEEN_EVENTS) {
        return null; // Skip event if sent too soon after the previous one
      }

      lastEventSentAt = now;

      // Ignore 'info' and 'warning' levels for less noise in logs
      if (event.level === 'info' || event.level === 'warning') {
        return null;
      }

      return event;
    },
  });

  console.log("Sentry initialized on client.");
} else {
  console.log("Sentry initialization skipped for local environment.");
}
