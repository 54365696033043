import { notification } from "antd";
import { setCookie } from "~/src/server/utils/commonUtils";
import { useRouter } from "next/router";
import { useContext } from "react";
import { AppContext } from "../context";

const useCompareProducts = () => {
  const router = useRouter();
  const { compareProductsSku, setCompareProductsSku, setShowProductCompare } =
    useContext<any>(AppContext);

  const compareProductHandler = (e: any, sku: any, validGuestUser: boolean) => {
    sku = sku.toString();
    e.preventDefault();
    if (validGuestUser) {
      router?.push(
        `/?epallet_login=true&source_path=${router?.asPath?.replace("&", "__")}`
      );
    } else {
      if (compareProductsSku?.length < 3) {
        setShowProductCompare(true);
        const isCompareActive: any = true;
        setCookie("isCompareActive", isCompareActive);

        if (!compareProductsSku?.includes(sku)) {
          setCompareProductsSku(compareProductsSku?.concat(sku));
          const json_sku: any = JSON.stringify(compareProductsSku?.concat(sku));
          setCookie("compareSkus", json_sku);
        } else {
          notification.warning({
            message: "Product already added",
            duration: 2,
          });
        }
      } else {
        notification.warning({
          message: "Compare list is full",
          duration: 2,
        });
      }
    }
  };
  return {
    compareProductHandler,
  };
};

export default useCompareProducts;
